.u-word-wrap {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.u-no-wrap-and-trim {
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
}
