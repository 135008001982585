.u-margin-top-sm {
    @include ln-respond-to('max-sm') {
        margin-top: $ln-space;
    }
}

.u-margins-top-sm-times-two {
    @include ln-respond-to('max-lg') {
        margin-top: $ln-space*2;
    }
}
