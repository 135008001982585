.u-border-orange {
    border: 1px solid $ln-color-orange;
}

.u-border-green {
    border: 1px solid $ln-color-success;
}

.u-border-grey {
    border: 1px solid #DADADA;
}