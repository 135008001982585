.b-scaffold-body {
  @include ln-respond-to('max-sm') {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.b-scaffold-max-width {
  max-width: 704px;
}
