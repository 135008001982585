.c-text-centre-align {
    text-align: center;
}

.c-text-arial-bold {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.c-text-color-matterhorn-dark-grey {
    color: #4c4c4c;
}

.c-text-color-monochrome-dark-grey {
    color: #404040;
}

.c-text-no-underline {
    text-decoration: none;
}
.c-text-button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    font: inherit;
    cursor: pointer;
}
.c-text-button-link:hover {
    color: $ln-color-orange;
}