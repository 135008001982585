.c-back-button-position {
    position: relative;
    top: -8px;
    
    @include ln-respond-to('max-sm') {
        left: -20px;
    }

    @include ln-respond-to('sm') {
        left: -30px;
    }
}
